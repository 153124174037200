import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import { Box } from "rebass";
import styled from "styled-components";

const ImageContainer = styled(Box)`
  max-width: 100%;
  width: 588px;
  flex-shrink: 1;
  flex-shrink: 0;
  margin: 0 auto 20px auto !important;
  user-select: none;
`;

export default () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "about-us.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, quality: 100, width: 588)
        }
      }
    }
  `);

  return (
    <ImageContainer>
      <GatsbyImage
        image={data.file.childImageSharp.gatsbyImageData}
        alt="CSAPATUNK"
      />
    </ImageContainer>
  );
};
