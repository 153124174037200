import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import { Box, Flex } from "rebass";
import ContentTitle from "../DesignSystem/ContentTitle";
import ContactTile from "./ContactTile";
import GoogleMap from "./GoogleMap";

export default (): React.ReactElement => {
  const data = useStaticQuery(graphql`
    query {
      contacts: allContactsYaml {
        edges {
          node {
            title
            content
            icon
            link
          }
        }
      }
    }
  `);
  const contacts = data.contacts.edges.map((edge: any) => edge.node);

  return (
    <>
      <ContentTitle headerId="contact" title="Kapcsolat" />
      <Flex>
        <Box>
          <Flex
            justifyContent="space-between"
            width="100%"
            flexWrap="wrap"
            height="100%"
            alignItems="center"
          >
            {contacts.map(({ title, content, icon, link }: any) => (
              <ContactTile
                key={title}
                icon={icon}
                title={title}
                link={link}
                content={content}
              />
            ))}
          </Flex>
        </Box>
        <Box style={{ flexShrink: 0 }}>
          <GoogleMap />
        </Box>
      </Flex>
    </>
  );
};
