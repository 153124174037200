import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";
import React from "react";
import styled from "styled-components";

const ProfileContainer = styled.div`
  display: inline-block;
  text-align: center;
  width: 100%;
  height: 350px;

  @media only screen and (min-width: 1000px) {
    flex: 1 0 31%;
    margin: 0 0 40px 0;
  }
`;

const ProfileImage = styled<any>(GatsbyImage)`
  width: 200px;
  height: 236px;
  margin: 0 auto;
  user-select: none;
`;

const ProfileName = styled.h2`
  margin-top: 10px;
  font-size: 1.8rem;
`;

const ProfileTitle = styled.div`
  color: ${({ theme }) => theme.colors.detail};
`;

export default ({
  name,
  title,
  image,
}: {
  name: string;
  title: string;
  image: IGatsbyImageData;
}): React.ReactElement => (
  <ProfileContainer>
    <ProfileImage image={image} alt={name} />
    <ProfileName>{name}</ProfileName>
    <ProfileTitle>{title}</ProfileTitle>
  </ProfileContainer>
);
