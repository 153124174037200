import React from "react";
import styled from "styled-components";

const QuickLink = styled.a`
  user-select: none;
  color: ${({ theme }) => theme.colors.offWhite};
  text-transform: uppercase;
  display: inline-block;
  font-size: 1.4rem;
  font-weight: 300;
  padding-top: 12px;

  &:hover {
    color: ${({ theme }) => theme.colors.nivodentGreen};
  }

  @media only screen and (max-width: 999px) {
    padding: 10px 0 10px 16px;
    width: 100%;
  }
`;

export default ({
  href,
  setOpen,
  children,
}: {
  href: string;
  setOpen?: (open: boolean) => void;
  children: React.ReactNode;
}): React.ReactElement => (
  <QuickLink href={href} onClick={() => setOpen && setOpen(false)}>
    {children}
  </QuickLink>
);
