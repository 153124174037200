import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import Slider from "react-slick";
import { Flex } from "rebass";
import ContentTitle from "../DesignSystem/ContentTitle";
import MobileOnly from "../DesignSystem/MobileOnly";
import ScreenOnly from "../DesignSystem/ScreenOnly";
import FeedbackItem from "./FeedbackItem";

export default (): React.ReactElement => {
  const data = useStaticQuery(graphql`
    query {
      feedback: allFeedbacksYaml {
        edges {
          node {
            name
            message
          }
        }
      }
    }
  `);
  const feedback = () =>
    data.feedback.edges
      .map((edge: any) => edge.node)
      .map(({ name, message }: any) => (
        <FeedbackItem key={name} name={name} message={message} />
      ));

  return (
    <>
      <ContentTitle headerId="feedback" title="RÓLUNK MONDTÁK" />
      <ScreenOnly>
        <Flex justifyContent="space-around" width="100%" flexWrap="wrap">
          {feedback()}
        </Flex>
      </ScreenOnly>
      <MobileOnly>
        <Slider
          dots={true}
          arrows={false}
          infinite={true}
          speed={1000}
          slidesToShow={1}
          autoplay={false}
        >
          {feedback()}
        </Slider>
      </MobileOnly>
    </>
  );
};
