import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import styled from "styled-components";
import ContactNumber from "./ContactNumber";
import NavOptions from "./NavOptions";

interface Openable {
  open: boolean;
}

const MobileNavbarButton = styled.button<Openable>`
  background-color: ${({ theme }) => theme.colors.headerBackground};
  color: ${({ theme, open }) =>
    open ? theme.colors.nivodentGreen : theme.colors.offWhite};
  font-size: 30px;
  float: right;
  margin: 6px 12px;
  border: 0;
  outline: 0;
`;

const NavbarContents = styled.div`
  position: fixed;
  top: 60px;
  right: 0;
  width: 200px;
  background: ${({ theme }) => theme.colors.headerBackground};
`;

const Separator = styled.div`
  width: 180px;
  margin: 6px auto;
  border-top: 1px solid ${({ theme }) => theme.colors.offWhite};
`;

const MenuCloser = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7f);
`;

export default (): React.ReactElement => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <MobileNavbarButton
        aria-label="Navigation"
        open={open}
        onClick={() => setOpen(!open)}
      >
        <FontAwesomeIcon icon={faBars} />
      </MobileNavbarButton>
      {open && (
        <MenuCloser onClick={() => setOpen(false)}>
          <NavbarContents>
            <NavOptions setOpen={setOpen} />
            <Separator />
            <ContactNumber />
          </NavbarContents>
        </MenuCloser>
      )}
    </>
  );
};
