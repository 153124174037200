import React from "react";
import styled from "styled-components";

const EmbeddedMap = styled.iframe<any>`
  margin-left: 30px;
  @media only screen and (max-width: 999px) {
    display: none;
  }
`;

export default (): React.ReactElement => (
  <EmbeddedMap
    title="Find us"
    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2729.447488001666!2d16.842439367073894!3d46.83488018452303!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4769281b9fbdfb03%3A0xfd02dc0cb534b552!2sNivodent%20Dental%20and%20Oral%20Surgery%20Center%2C%20Zalaegerszeg!5e0!3m2!1sen!2shu!4v1602679829236!5m2!1sen!2shu"
    width="600"
    maxWidth="100%"
    height="450"
    border="0"
    frameBorder="0"
    aria-hidden="false"
  ></EmbeddedMap>
);
