import React from "react";
import QuickLink from "./QuickLink";

export default ({
  setOpen,
}: {
  setOpen?: (open: boolean) => void;
}): React.ReactElement => (
  <>
    <QuickLink setOpen={setOpen} href="#about-us">
      RÓLUNK
    </QuickLink>
    <QuickLink setOpen={setOpen} href="#services">
      Kezelések
    </QuickLink>
    <QuickLink setOpen={setOpen} href="#our-team">
      CSAPATUNK
    </QuickLink>
    <QuickLink setOpen={setOpen} href="#support">
      TÁMOGATÁS
    </QuickLink>
    <QuickLink setOpen={setOpen} href="#contact">
      KAPCSOLAT
    </QuickLink>
  </>
);
