import React from "react";
import styled from "styled-components";
import AboutUs from "../components/AboutUs";
import Contact from "../components/Contact";
import Feedback from "../components/Feedback";
import Navbar from "../components/Navbar";
import OurTeam from "../components/OurTeam";
import Services from "../components/Services";
import Slider from "../components/Slider";
import Support from "../components/Support";
import Layout from "../components/layout";
import SEO from "../components/seo";

const Block = styled.div`
  padding: 40px 20px 60px 20px;
  margin: 0 auto;
  max-width: 1200px;
  width: 100%;
  overflow: hidden;
`;

const ContrastBlock = styled.div`
  background: rgb(235, 235, 235);
  min-height: 100px;
  width: 100%;
`;

const App = (): React.ReactElement => (
  <Layout>
    <Navbar />
    <Slider />
    <Block>
      <AboutUs />
    </Block>
    <ContrastBlock>
      <Block>
        <Services />
      </Block>
    </ContrastBlock>
    <Block>
      <OurTeam />
    </Block>
    <ContrastBlock>
      <Block>
        <Feedback />
      </Block>
    </ContrastBlock>
    <Block>
      <Support />
    </Block>
    <ContrastBlock>
      <Block>
        <Contact />
      </Block>
    </ContrastBlock>
    <SEO />
  </Layout>
);

export default App;
