import React from "react";
import { Flex } from "rebass";
import styled from "styled-components";
import ContactNumber from "./ContactNumber";
import Logo from "./Logo";
import MobileNavMenu from "./MobileNavMenu";
import NavOptions from "./NavOptions";

export const navbarHeight = "60px";

const NavbarContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: ${navbarHeight};
  background: ${({ theme }) => theme.colors.headerBackground};
  z-index: 10000;
  overflow: hidden;
  color: ${({ theme }) => theme.colors.offWhite};
`;

const ScreenNavbarContent = styled<any>(Flex)`
  max-width: 1200px;
  margin: 0 auto !important;
`;

const NavbarPageLayoutSpacer = styled.div`
  height: ${navbarHeight};
`;

const ScreenNavbar = styled.div`
  @media only screen and (max-width: 999px) {
    display: none !important;
  }
`;

const MobileNavbar = styled.div`
  @media only screen and (min-width: 1000px) {
    display: none !important;
  }
`;

export default (): React.ReactElement => (
  <>
    <NavbarContainer>
      <ScreenNavbar>
        <ScreenNavbarContent justifyContent="space-around" flexWrap="nowrap">
          <Logo />
          <ContactNumber />
          <NavOptions />
        </ScreenNavbarContent>
      </ScreenNavbar>
      <MobileNavbar>
        <Logo />
        <MobileNavMenu />
      </MobileNavbar>
    </NavbarContainer>
    <NavbarPageLayoutSpacer />
  </>
);
