import { graphql, useStaticQuery } from "gatsby";
import React, { useState } from "react";
import Slider from "react-slick";
import { Box, Flex } from "rebass";
import ContentTitle from "../DesignSystem/ContentTitle";
import MobileOnly from "../DesignSystem/MobileOnly";
import MoreInfo from "../DesignSystem/MoreInfo";
import ScreenOnly from "../DesignSystem/ScreenOnly";
import SubContentTitle from "../DesignSystem/SubContentTitle";
import Profile from "./profile";

type RoleType = "doctor" | "assistant";

export default (): React.ReactElement => {
  const [activeRole, setActiveRole] = useState<RoleType>("doctor");

  const data = useStaticQuery(graphql`
    query {
      profiles: allProfilesYaml {
        edges {
          node {
            name
            role
            title
            image {
              childImageSharp {
                gatsbyImageData(
                  layout: FIXED
                  quality: 100
                  width: 200
                  height: 236
                )
              }
            }
          }
        }
      }
    }
  `);
  const profiles = () =>
    data.profiles.edges
      .map((edge: any) => edge.node)
      .filter(({ role }: any) => role === activeRole)
      .map(({ name, title, image }: any) => (
        <Profile
          key={name}
          name={name}
          title={title}
          image={image.childImageSharp.gatsbyImageData}
        />
      ));

  const ourDoctors = "orvosaink";
  const ourAssistants = "asszisztenseink";

  return (
    <>
      <ContentTitle headerId="our-team" title="CSAPATUNK" />
      <SubContentTitle
        title={activeRole === "doctor" ? ourDoctors : ourAssistants}
      />
      <MoreInfo
        title={activeRole === "doctor" ? ourAssistants : ourDoctors}
        onClick={() =>
          setActiveRole(activeRole === "doctor" ? "assistant" : "doctor")
        }
      />
      <ScreenOnly>
        <Flex
          justifyContent="space-around"
          width="100%"
          flexWrap="wrap"
          marginTop="30px"
        >
          {profiles()}
        </Flex>
      </ScreenOnly>
      <MobileOnly>
        <Box marginTop="20px" />
        <Slider
          dots={true}
          arrows={false}
          infinite={true}
          speed={1000}
          slidesToShow={1}
          autoplay={false}
        >
          {profiles()}
        </Slider>
      </MobileOnly>
    </>
  );
};
