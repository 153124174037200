import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Box, Flex } from "rebass";
import { navbarHeight } from "./index";

export default (): React.ReactElement => (
  <Flex height="100%" paddingLeft="16px">
    <Box height="100%" style={{ lineHeight: navbarHeight, margin: "0 6px" }}>
      <FontAwesomeIcon icon={faPhone} />
    </Box>
    <Box padding="6px">
      <Box width="100%" style={{ textAlign: "center" }}>
        NIVODENT
      </Box>
      <Box width="100%" style={{ textAlign: "center" }}>
        +36 92 327 033
      </Box>
    </Box>
  </Flex>
);
