import React from "react";
import { Box, Flex } from "rebass";
import ContentBody from "../DesignSystem/ContentBody";
import ContentTitle from "../DesignSystem/ContentTitle";
import Paragraph from "../DesignSystem/Paragraph";
import InfoBlokkImage from "./InfoBlokkImage";

const Support = () => (
  <>
    <ContentTitle headerId="support" title="Támogatás" />
    <Flex style={{ flexWrap: "wrap-reverse" }}>
      <Box style={{ flex: 1, minWidth: "320px" }}>
        <ContentBody>
          <Paragraph>
            <b>Kedvezményezett neve:</b>
            <br /> Nivodent Fogászati és Szájsebészeti Szolgáltató és
            Kereskedelmi BT
          </Paragraph>
          <Paragraph>
            <b>A projekt címe:</b>
            <br /> Készlet beszerzés, működési költség, bérköltség,
            finanszírozás
          </Paragraph>
          <Paragraph>
            <b>Támogatás összege:</b>
            <br />
            20.000.000 Ft
          </Paragraph>
          <Paragraph>
            <b>Hitelprogram azonosító jele:</b>
            <br /> GINOP-8.3.5-18/B_4
          </Paragraph>
        </ContentBody>
      </Box>
      <InfoBlokkImage />
    </Flex>
  </>
);

export default Support;
