import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import { Box } from "rebass";
import styled from "styled-components";

const ImageContainer = styled(Box)`
  max-width: 100%;
  width: 858px;
  flex: 1;
  margin: 0 auto 20px auto !important;
  user-select: none;
`;

export default () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "infoblokk.png" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, quality: 100, width: 588)
        }
      }
    }
  `);

  return (
    <ImageContainer style={{ minWidth: "320px" }}>
      <GatsbyImage
        image={data.file.childImageSharp.gatsbyImageData}
        alt="CSAPATUNK"
      />
    </ImageContainer>
  );
};
