import React from "react";
import styled from "styled-components";

const ContentHeader = styled.h2`
  color: ${({ theme }) => theme.colors.offBlack};
  text-transform: uppercase;
  font-size: 2rem;
  font-weight: 200;
  width: 100%;
  text-align: center;
  margin: 0;
`;

export default ({ title }: { title: string }): React.ReactElement => (
  <ContentHeader>{title}</ContentHeader>
);
