import React from "react";
import styled from "styled-components";

const ContentHeader = styled.h1`
  color: ${({ theme }) => theme.colors.nivodentGreen};
  text-transform: uppercase;
  font-size: 2.4rem;
  font-weight: 200;
  padding: 10px 0;
  margin-bottom: 20px;
  width: 100%;
  text-align: center;
  padding-top: 70px; // Account for fixed header
  margin-top: -60px; // Account for fixed header
`;

const ContentUnderline = styled.div`
  width: 30px;
  border-bottom: 1px solid #999;
  margin: 8px auto;
`;

interface ContentTitleProps {
  headerId: string;
  title: string;
}

export default ({ headerId, title }: ContentTitleProps): React.ReactElement => (
  <ContentHeader id={headerId}>
    {title}
    <ContentUnderline />
  </ContentHeader>
);
