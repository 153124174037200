import React from "react";
import { Box, Flex } from "rebass";
import ContentBody from "../DesignSystem/ContentBody";
import ContentTitle from "../DesignSystem/ContentTitle";
import Paragraph from "../DesignSystem/Paragraph";
import ScreenOnly from "../DesignSystem/ScreenOnly";
import AboutUsImage from "./AboutUsImage";

export default (): React.ReactElement => (
  <>
    <ContentTitle headerId="about-us" title="RÓLUNK" />
    <Flex flexWrap="wrap-reverse" alignItems="start">
      <Box style={{ flexShrink: 1, flexGrow: 1, flexBasis: "360px" }}>
        <ContentBody>
          <Paragraph>
            A Nivodent Fogászati és Szájsebészeti Centrum több, mint 30 éves
            múltra tekint vissza Zalaegerszegen. Alapítója, Dr. Sáska József,
            vezető főorvos, a Zala Megyei Fogászati és Szájsebészeti
            Szakfelügyelet főorvosa, a legjelentősebb szakmai tekintélye a
            megyének. Tapasztalatával és szakmai tudásával, olyan komplex
            szolgáltatást nyújtó rendelőt hozott létre, ahol a fogászati
            problémák teljes palettájára megoldást kínálnak, 6 szakorvos
            specialista segítségével.
          </Paragraph>
          <ScreenOnly>
            <Paragraph>
              A klinika nagy hangsúlyt fektet a szakmai újdonságok bevezetésére,
              ezért a legmodernebb eszközpark és a legkorszerűbb, fájdalommentes
              kezelések várják a pácienseket, minden munkanapon.
            </Paragraph>
          </ScreenOnly>
        </ContentBody>
      </Box>
      <AboutUsImage />
    </Flex>
  </>
);
