import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import { Flex } from "rebass";
import ContentTitle from "../DesignSystem/ContentTitle";
import Service from "./Service";

export default (): React.ReactElement => {
  const data = useStaticQuery(graphql`
    query {
      services: allServicesYaml {
        edges {
          node {
            name
            entries
          }
        }
      }
    }
  `);
  const services = data.services.edges.map((edge: any) => edge.node);

  return (
    <>
      <ContentTitle headerId="services" title="Kezelések" />
      <Flex justifyContent="space-around" width="100%" flexWrap="wrap">
        {services.map(({ name, entries }: any) => (
          <Service key={name} name={name} entries={entries} />
        ))}
      </Flex>
    </>
  );
};
