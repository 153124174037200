import {
  faFacebookF,
  faInstagramSquare,
  IconDefinition,
} from "@fortawesome/free-brands-svg-icons";
import {
  faBuilding,
  faClock,
  faEnvelope,
  faPhone,
  faQuestion,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import styled from "styled-components";

const ContactTileContainer = styled.div`
  text-align: center;
  height: 165px;
  margin-bottom: 30px;
  border: white solid 8px;
  width: calc(100% * (1 / 3) - 10px - 1px);

  @media only screen and (max-width: 700px) {
    width: calc(100% * (1 / 2) - 10px - 1px);
  }

  @media only screen and (max-width: 370px) {
    width: calc(100% - 10px - 1px);
  }
`;

const ContactTileIcon = styled<any>(FontAwesomeIcon)`
  font-size: 2rem !important;
  color: ${({ theme }) => theme.colors.nivodentGreen};
`;

const ContactTileTitle = styled.h2`
  margin: 15px 0 10px 0;
`;

const ContactTileContent = styled.ul`
  list-style: none;
  padding: 0;
  font-size: 0.9rem;
`;

const ContactTileContentEntry = styled.li``;

const ContactTileContentBlock = ({
  content,
}: {
  content: string[];
}): React.ReactElement => (
  <>
    {content.map((line) => (
      <ContactTileContentEntry key={line}>{line}</ContactTileContentEntry>
    ))}
  </>
);

const iconMap = new Map<string, IconDefinition>([
  ["address", faBuilding],
  ["phone", faPhone],
  ["email", faEnvelope],
  ["time", faClock],
  ["facebook", faFacebookF],
  ["instagram", faInstagramSquare],
]);

export default ({
  title,
  content,
  icon,
  link,
}: {
  title: string;
  content: string[];
  icon: string;
  link?: string;
}): React.ReactElement => (
  <ContactTileContainer>
    <ContactTileIcon icon={iconMap.get(icon) || faQuestion} />
    <ContactTileTitle>{title}</ContactTileTitle>
    {link ? (
      <a target="_" href={link}>
        <ContactTileContent>
          <ContactTileContentBlock content={content} />
        </ContactTileContent>
      </a>
    ) : (
      <ContactTileContent>
        <ContactTileContentBlock content={content} />
      </ContactTileContent>
    )}
  </ContactTileContainer>
);
