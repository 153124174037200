import React from "react";
import styled from "styled-components";

const FeedbackBox = styled.article`
  position: relative;
  margin: 0 5px 40px 5px;
  max-width: 280px;

  @media only screen and (max-width: 999px) {
    margin: 0 auto;
    margin-bottom: 20px;
  }
`;

const FeedbackName = styled.h2`
  font-weight: bold;
  margin-left: 18px;
  padding-top: 20px;
`;

const borderColor = "#ddd";
const FeedbackMessage = styled.div`
  position: relative;
  font-style: italic;
  background: #fff;
  border: 2px solid ${borderColor};
  padding: 10px;
  min-height: 244px;
  border-radius: 6px;

  &:before {
    content: " ";
    position: absolute;
    width: 0;
    height: 0;
    left: 14px;
    top: 240px;
    border: 12px solid;
    border-color: ${borderColor} ${borderColor} transparent transparent;

    @media only screen and (max-width: 300px) {
      display: none;
    }
  }

  &:after {
    content: " ";
    position: absolute;
    width: 0;
    height: 0;
    left: 16px;
    top: 240px;
    border: 10px solid;
    border-color: #fff #fff transparent transparent;

    @media only screen and (max-width: 300px) {
      display: none;
    }
  }
`;

export default ({
  name,
  message,
}: {
  name: string;
  message: string;
}): React.ReactElement => (
  <FeedbackBox>
    <FeedbackMessage>{'"' + message + '"'}</FeedbackMessage>
    <FeedbackName>{name}</FeedbackName>
  </FeedbackBox>
);
