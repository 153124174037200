import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import styled from "styled-components";
import MoreInfo from "../DesignSystem/MoreInfo";

const ServiceTile = styled.article`
  width: 280px;
  height: 82px;
  background: #fff;
  border: 1px solid #ddd;
  margin: 5px;
  cursor: pointer;
  user-select: none;
  overflow: hidden;

  @media only screen and (min-width: 1000px) {
    height: 115px;
  }
`;

const ServiceName = styled.h2`
  padding-top: 15px;
  text-align: center;
  margin-bottom: 3px;

  @media only screen and (min-width: 1000px) {
    padding-top: 30px;
    margin-bottom: 10px;
  }
`;

const ServiceEntriesContainer = styled.ul`
  list-style: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10000;
  background: rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
`;

const ServiceEntriesBox = styled.div`
  background: #fff;
  padding: 30px 25px;
  min-width: 300px;

  @media only screen and (min-width: 1000px) {
    padding: 30px 40px;
  }
`;

const ServiceEntriesBoxTitle = styled.h2`
  margin: 10px 0 20px 0;
  text-align: center;
  color: ${({ theme }) => theme.colors.nivodentGreen};
  text-transform: uppercase;

  @media only screen and (min-width: 1000px) {
    margin: 20px 0;
  }
`;

const ServiceEntry = styled.li`
  line-height: 40px;
  font-size: 0.9rem;
  word-wrap: none;
`;

const Icon = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.colors.nivodentGreen};
  margin-right: 8px;
`;

export default ({
  name,
  entries,
}: {
  name: string;
  entries: string[];
}): React.ReactElement => {
  const [show, setShow] = useState(false);

  return (
    <>
      <ServiceTile onClick={() => setShow(true)}>
        <ServiceName>{name}</ServiceName>
        <MoreInfo title="további részletek" />
      </ServiceTile>
      {show && (
        <ServiceEntriesContainer onClick={() => setShow(false)}>
          <ServiceEntriesBox>
            <ServiceEntriesBoxTitle>{name}</ServiceEntriesBoxTitle>
            {entries.map((entry) => (
              <ServiceEntry key={entry}>
                <Icon icon={faAngleRight} />
                {entry}
              </ServiceEntry>
            ))}
          </ServiceEntriesBox>
        </ServiceEntriesContainer>
      )}
    </>
  );
};
