import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import styled from "styled-components";

const Image = styled<any>(GatsbyImage)`
  user-select: none;
  margin-left: 10px;
  width: 60px;
  height: 45px;
  margin-top: 7px;

  @media only screen and (min-width: 1000px) {
    width: 80px;
    height: 60px;
    margin-top: 0;
  }
`;

export default () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, quality: 100, width: 80)
        }
      }
    }
  `);

  return (
    <a href="#">
      <Image
        image={data.file.childImageSharp.gatsbyImageData}
        alt="Nivodent Logo"
      />
    </a>
  );
};
