import { faAngleDoubleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import styled from "styled-components";

const MoreInfoContainer = styled.div`
  font-size: 0.9rem;
  text-align: center;
  color: ${({ theme }) => theme.colors.offBlack};
  margin: 0 auto;
  user-select: none;
  > span {
    cursor: pointer;
  }
`;

export default ({
  title,
  onClick,
}: {
  title: string;
  onClick?: () => void;
}): React.ReactElement => (
  <MoreInfoContainer>
    <span onClick={onClick}>
      {title} <FontAwesomeIcon icon={faAngleDoubleRight} />
    </span>
  </MoreInfoContainer>
);
