import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import Slider from "react-slick";
import styled from "styled-components";
import "../../styles/slick/slick-theme.css";
import "../../styles/slick/slick.css";

const SliderContainer = styled.div`
  position: relative;
`;

const SlideImage = styled<any>(GatsbyImage)`
  filter: brightness(35%);
`;

const SlideTitle = styled.h3`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  white-space: nowrap;
  user-select: none;
  color: white;
  font-size: 4rem;

  @media only screen and (max-width: 999px) {
    font-size: 2rem;
  }
`;

export default (): React.ReactElement => {
  const data = useStaticQuery(graphql`
    query {
      slides: allSlidesYaml {
        edges {
          node {
            title
            image {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED, width: 2560, height: 870)
              }
            }
          }
        }
      }
    }
  `);
  const slides = data.slides.edges.map((edge: any) => edge.node);

  return (
    <Slider
      dots={false}
      arrows={false}
      infinite={true}
      speed={1000}
      slidesToShow={1}
      autoplay={true}
      autoplaySpeed={2500}
    >
      {slides.map((slide: any, index: number) => (
        <SliderContainer key={slide.title}>
          <SlideImage
            image={slide.image.childImageSharp.gatsbyImageData}
            loading={index === 0 ? "eager" : "lazy"}
          />
          <SlideTitle>{slide.title}</SlideTitle>
        </SliderContainer>
      ))}
    </Slider>
  );
};
