import React from "react";
import styled from "styled-components";

const ContentBodyWrapper = styled.div`
  padding: 0 10px;
`;

export default ({
  children,
}: {
  children: React.ReactNode;
}): React.ReactElement => <ContentBodyWrapper>{children}</ContentBodyWrapper>;
